import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import AppProvider from "./context/context";
import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${Cookies.get("token")}`;
  return request;
});
axios.interceptors.response.use((response) => {
  response.headers.Authorization = `Bearer ${Cookies.get("token")}`;
  return response;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppProvider>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </AppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
