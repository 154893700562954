import React from "react";
import Mainpanelnav from "../mainpanel-header/Mainpanelnav";

const ListingSpace = () => {
  return <div className="mx-5 mt-3">
    <Mainpanelnav />
  </div>;
};

export default ListingSpace;
